import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAfiliados } from "../../reducers/afiliado_slice";
import FormPropietario from "../propietario/FormPropietario";
import { fetchPersonas } from "../../reducers/persona_slice";
import { fetchPropietarios } from "../../reducers/propietario_slice";
import ListInmuebles from "../inmueble/ListInmuebles";
import { fetchInmuebles } from "../../reducers/inmueble_slice";
import SelectPersona from "../persona/SelectPersona";
import SelectInmueble from "../inmueble/SelectInmueble";
import { formatNumber } from "../../util/customFormatter";
import { BsCamera, BsEye, BsEyeSlash } from "react-icons/bs";


const TabPago = ({ tipoProceso, errors, 
    datos,
    tipoPago,
    depositos,
    handleChangeDeposito,
    paymentSaved,
    handleFileDepositoChange,
    toggleImageVisibility,
    startCamera,
    imagenVisible,
    handleAddDeposito,
    handleRemoveDeposito,
    handleChangeTipoPago,
    handleChangeDescuento,
    esResellado,
    totalPago,
    handleProforma ,
    imagenPreview,
    setDatos,
    setErrors
}) => {
const bancos = useSelector(state => state.banco.bancos || []);
    
const pagosVisados= datos.recibos?.filter(reci => reci.concepto.toLowerCase().includes("visado") ||  reci.concepto.toLowerCase().includes("resellado"))

const handleCheckboxVivienda= ( checked ) => {

    const { errorViviendaPropia, conceptoDescuento, descuento } = handleChangeDescuento("viviendapropia", checked)
    
    setDatos((prevDatos) =>({
        ...prevDatos,
        conceptoDescuento,
        descuento
    }))
    setErrors(prevE=>({...prevE, viviendaPropia:errorViviendaPropia}))
}
    return (
        <>
          <Row className={`${errors.depositos ? 'border-danger' : ''}`}>
                  
                  {/* Mostrar los recibos previos */}
                    {(tipoProceso!=="transferir" && pagosVisados && pagosVisados.length > 0) && (
                        <div className="mb-3">
                            <h5>Pagos Realizados</h5>
                            <Card className="p-3">
                                <Card.Body>
                                    {pagosVisados.map((recibo, index) => (
                                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                            <div style={{ fontWeight: 'bold', color: '#495057' }}>N° recibo: {recibo.nroRecibo}</div>
                                            <div style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                                                {formatNumber(recibo.monto)} Bs.
                                            </div>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </div>
                    )}
            
            {imagenPreview && (
                        <img
                            src={imagenPreview}
                            alt="Imagen de Depósito"
                            style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            maxWidth: '150px',
                            border: '1px solid #ccc',
                            padding: '5px',
                            borderRadius: '5px',
                            backgroundColor: '#fff'
                            }}
                        />
                        )}
                {tipoPago==="B"&&  <div className={`table-container ${errors.depositos ? 'border-danger' : ''}`}>
                        <h5>Registrar Depósitos</h5>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Banco</th>
                                    <th>Monto</th>
                                    <th>Fecha Depósito</th>
                                    <th>Documento</th>
                                    <th>Archivo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depositos.map((deposito, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                value={deposito.id_banco}
                                                onChange={(e) => handleChangeDeposito(index, 'id_banco', e.target.value)}
                                                disabled={paymentSaved}
                                            >
                                                <option value="">Seleccione un banco</option>
                                                {bancos.map((banco) => (
                                                    <option key={banco.id} value={banco.id}>
                                                        {banco.banco}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                value={deposito.monto}
                                                onChange={(e) => handleChangeDeposito(index, 'monto', e.target.value)}
                                                required
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="date"
                                                value={deposito.fecha_deposito}
                                                onChange={(e) => handleChangeDeposito(index, 'fecha_deposito', e.target.value)}
                                                required
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={deposito.docum}
                                                onChange={(e) => handleChangeDeposito(index, 'docum', e.target.value)}
                                                disabled={paymentSaved}
                                            />
                                        </td>
                                        <td style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => handleFileDepositoChange(index, e.target.files[0])}
                                                disabled={paymentSaved}
                                            />
                                            {deposito.archivo && (
                                                <>
                                                    <span style={{ marginLeft: '10px' }}>{deposito.archivo.name}</span>
                                                    <button
                                                        className="icon-button"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => toggleImageVisibility(index)}
                                                        type="button"
                                                    >
                                                        {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                                                    </button>
                                                </>
                                            )}
                                            <button
                                                className="icon-button"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => startCamera(index)}
                                                type="button"
                                            >
                                                <BsCamera />
                                            </button>
                                        </td>
                                        <td>
                                            {!paymentSaved && index === depositos.length - 1 && (
                                                <button className="icon-button" onClick={handleAddDeposito} type="button">
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            )}
                                            {!paymentSaved && depositos.length > 1 && (
                                                <button className="icon-button" onClick={() => handleRemoveDeposito(index)}  type="button">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {errors.depositos && (
                        <p className="text-danger mt-2">
                            Todos los datos de los depósitos deben ser llenados, incluyendo el archivo de respaldo correspondiente a cada uno.
                        </p>
                    )}
                    </div>}
                    <Col className="soft-labels mt-3 mb-3">
                            <Form.Check
                                type="checkbox"
                                id="efectivo-checkbox"
                                label="Pago en efectivo"
                                onChange={handleChangeTipoPago}
                                checked={tipoPago==="C"}
                            />
                            
                        </Col>
                        <Col className="soft-labels mt-3 mb-3">
                            <Form.Check
                                type="checkbox"
                                id="vivienda-propia-checkbox"
                                label="Vivienda propia"
                                onChange={(e)=>handleCheckboxVivienda(e.target.checked)}
                                checked={datos.conceptoDescuento === "viviendapropia"}
                            />
                                {errors.viviendaPropia && (
                                <p className="text-danger mt-2">
                                    El arquitecto debe ser propietario para habilitar el descuento por Vivienda Propia (No aplicable en transferencia de propiedad)
                                </p>
                            )}
                        </Col>
                
                        
                    <div>
                    {esResellado && <><strong>Visación:</strong>{" "}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(datos.comision_cat)} Bs.</span>
                        </>
                     }
                        {esResellado && <>
                        <br></br>
                       {tipoProceso === "editar" &&<><strong>Total pagos realizados:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(totalPago.pagadoAnteriormente)} Bs.</span>
            </>}
                      </>}
                   
                     
                       {(esResellado) && <hr></hr>}
                        <strong>Monto a pagar:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.porPagar)} Bs.</span>
                        {tipoPago === "B" && <button className="btn-sm btn-mine-tertiary"  type="button"  onClick={handleProforma} disabled={!totalPago.porPagar > 0 || !datos.proyectista}>
                            Proforma
                        </button>}

                        {datos.conceptoDescuento === "viviendapropia"&&<> 
                            <br></br>
                      <strong>Descuento:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.descuento)} Bs.</span>
                        <hr></hr>
                        <strong>Total con descuento:</strong>{' '}
                        <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalPago.totalConDescuento)} Bs.</span>
                       
                        </>}
                        <br></br>
                        <strong>Total pagado:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>{formatNumber(totalPago.pagado)} Bs.</span>
                
                        {errors.totalPago && (
                            <div className="invalid-feedback d-block">
                                El monto "Total pagado" debe ser igual al "Monto a pagar" calculado.
                            </div>
                        )}
                    </div>
 
                
                </Row>

        </>
    )
}

export default TabPago;